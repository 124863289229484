<template>
  <div class="page-layout event-page">
    <HeaderIntroImage underlined :headerMetadata="pageData">
      <a class="btn slim" href="https://qt.eu/about-quantum-flagship/events/?q=&tags%5B%5D=education-outreach" target="_blank">See Education & Outreach events at qt.eu</a>
    </HeaderIntroImage>
    <div class="fullwidth" style="overflow: hidden;">
      <div class="container">
        <br><br>
        <div class="list-wrapper">
          <FiltersAside @reset="reset()">
            <input type="text" name="search" v-model="searchField" placeholder="Free search..." @input="searchAfterTyping()">
            <select v-if="wgList.length" name="country" id="" class="filters--dropdown" @input="addWG">
              <option value="all">Filter by Working Group</option>
              <option v-for="(l, ind) in wgList" :key="'lang'+ind" :value="l">{{ l }}</option>
            </select>
            <div class="active-filter icon-right close" v-for="(l, ind) in wgFilter" :key="'targ'+ind" @click="removeWG(l)">{{ l }}</div>
          </FiltersAside>
          
          <div class="list" v-if="filteredEvents.length || filteredPast.length">
            <h2 v-if="filteredEvents.length">QTEdu Organised Events</h2>
            <ArticlesList v-if="filteredEvents.length">
              <EventCard v-for="(ev, index) in this.filteredEvents" :key="'ev'+index" 
                :cat="categoryData(ev)" :articleData="ev" :routesData="routesData"/>
            </ArticlesList>

            <h2 v-if="filteredPast.length">QTEdu Past Events</h2>

            <ArticlesList v-if="filteredPast.length">
              <EventCard v-for="(ev, index) in this.filteredPast" :key="'ev'+index" 
                :cat="categoryData(ev)" :articleData="ev" :routesData="routesData"/>
            </ArticlesList>

            <div class="other-events" v-if="loadMore==false">
              <button @click="loadMoreEvents()" class="load-more btn slim">
                Load more
              </button>
            </div>

       

          </div>
          <div v-else>
            <h3>No Data</h3>
          </div>
        </div>
      </div>
    </div>
      <banner-events></banner-events>

  </div>
</template>

<script>
import BannerEvents from '../components/BannerEvents.vue'
import FiltersAside from '../components/layout-blocks/FiltersAside.vue'
import ArticlesList from '../components/layout-blocks/ArticlesList.vue'
import EventCard from '../components/layout-blocks/EventCard.vue'
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import { fetchNodes, fetchSinglePage } from '../libs/drupalClient'
export default {
  name: 'events',
  components: {
    BannerEvents,
    HeaderIntroImage,
    EventCard,
    ArticlesList,
    FiltersAside
  },
  props: {},
  data: () => {
    return {
      pageData: {
        attributes: {
          title: "Events"
        }
      },
      currentPageID: "828a473f-4e9e-4790-ac5b-164822a0b4bf",
      eventsData: null,
      pastEvents: null,
      totalEvents: null,
      wgFilter: [],
      loadMore:false,
      searchFilter: '',
      searchField: '',
      timer: undefined
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    },
    cat() {
      return { nid: 14, title: ''}
    },
    routesData() {
      return this.$store.state.routesData
    },
    filteredEvents() {
      var evts = []
      if(this.eventsData) {
        evts = this.eventsData

        if(this.wgFilter.length) {
          evts = evts.filter(e => {
            var found = false  
            if(e.field_ref_working_group && this.wgFilter.includes(e.field_ref_working_group.attributes.title))
              found = true
            return found
          })
        }

        if(this.searchFilter!='') {
          evts = evts.filter(e => {
            if(e.attributes.title.toLowerCase().includes(this.searchFilter.toLowerCase())
              || (e.attributes.field_event_location_address && e.attributes.field_event_location_address.toLowerCase().includes(this.searchFilter.toLowerCase()))
              || (e.attributes.field_event_location_name && e.attributes.field_event_location_name.toLowerCase().includes(this.searchFilter.toLowerCase())))
              return true
          })
        }
      }
      return evts
    },
    filteredPast() {
      var evts = []
      
      if(this.pastEvents) {

        evts = this.pastEvents

        if(this.wgFilter.length) {
          evts = evts.filter(e => {
            var found = false  
            if(e.field_ref_working_group && this.wgFilter.includes(e.field_ref_working_group.attributes.title))
              found = true
            return found
          })
        }

        if(this.searchFilter!='') {
          evts = evts.filter(e => {
            if(e.attributes.title.toLowerCase().includes(this.searchFilter.toLowerCase())
              || (e.attributes.field_event_location_address && e.attributes.field_event_location_address.toLowerCase().includes(this.searchFilter.toLowerCase()))
              || (e.attributes.field_event_location_name && e.attributes.field_event_location_name.toLowerCase().includes(this.searchFilter.toLowerCase())))
              return true
          })
        }
      }

      //carica solo 4 all'inizio poi tutti se click su load more btn
      if(this.loadMore==false){
        
       //console.log('yooo');

        return evts.slice(0,4)

      } else {

       //console.log('else');

        return evts
      }

    },
    wgList() {
      var wgs = []
      if(this.totalEvents) {
        this.totalEvents.forEach(e => {
          if(e.field_ref_working_group && !wgs.includes(e.field_ref_working_group.attributes.title))
            wgs.push(e.field_ref_working_group.attributes.title)
        })
      }
      return wgs.sort((a,b) => { return a>b ? 1 : -1 })
    }
  },
  methods: {
    loadMoreEvents(){
      this.loadMore = true
    },
    searchAfterTyping() {

      this.loadMore=true

      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        this.searchFilter = this.searchField
      }, 300);
    },
    addWG(event) {
      
      if(!this.wgFilter.includes(event.target.value))
        this.wgFilter.push(event.target.value)
        this.loadMore=true

      event.target.value = 'all'
    },
    removeWG(l) {
      var ind = this.wgFilter.indexOf(l)
      this.wgFilter.splice(ind, 1)
    },
    reset() {
      this.loadMore=false
      this.wgFilter = []
      this.searchFilter = ''
      this.searchField = ''
    },
    categoryData(n) {
      var cd = {
        nid: 0,
        name: ""
      }

      if(n.field_ref_working_group)
        switch(n.field_ref_working_group.attributes.drupal_internal__nid) {
          case 14:
            cd.nid = 14
            cd.name = 'School education and Public outreach'
            break;
          case 15:
            cd.nid = 15
            cd.name = 'Educational initiatives in higher education'
            break;
          case 16:
            cd.nid = 16
            cd.name = 'Lifelong learning and workforce training'
            break;
          case 17:
            cd.name = "Educational research in Quantum Technology"
            cd.nid = 17
            break;
          case 18:
            cd.nid = 18
            cd.name = 'Equity and inclusion for QT educational initiatives'
            break;
          default:
            cd.nid = 0
            break;
        }

      return cd
    }
  },
  async mounted() {
    this.reset()

    fetchSinglePage(this.currentPageID, {}).then(res => this.pageData=res)

    await fetchNodes('event', {
      include: ['field_ref_working_group', 'field_image']
    })
    .then(res => {

      this.totalEvents = res
      //console.log('this.totalEvents', this.totalEvents);

      this.eventsData = res
      .filter(e => new Date(e.attributes.field_end_date).getTime() >= Date.now())
      .sort((a,b) => { return a.attributes.field_start_date > b.attributes.field_start_date ? 1 : -1 })


      this.pastEvents = res
      .filter(e => new Date(e.attributes.field_end_date).getTime() < Date.now())
      .sort((a,b) => { return a.attributes.field_start_date > b.attributes.field_start_date ? -1 : 1 })

      //console.log('events', this.eventsData)
    })

    if(this.$store.state.add_wg_filter)
      this.wgFilter.push(this.$store.state.add_wg_filter)

    this.$store.commit('SET_WG_FILTER', '')
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";

.btn.load-more {
  color: #fff;
  margin: 3rem auto 2rem;

  &:hover {
    background: linear-gradient(to right, #FF7A07 20%, #FF0576 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 1px solid #FF0576;
  }
}

.event-page ::v-deep .header-intro .btn {
  background: linear-gradient(to right, #FF7A07 20%, #FF0576 80%);
  display: block;
  -webkit-text-fill-color: #fff;
  color: #fff;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
    transition: 0.2s;
  }
}

.active-filter {
  padding: .25em;
  padding-right: 1.5em;
  font-size: 14px;
  color: $middleBlue;

  &:before { padding-right: .5em; }
  &:hover {
    background-color: $middleBlue;
    color: #fff;
    cursor: pointer;
  }
  
}

.event-page {

  ::v-deep .teaser {
    color: #CDD0DE;
    max-width: 600px;
    opacity: 1;
    line-height: 1.5rem;

    &:before {
      content: "Quantum Flagship Education and Outreach Events";
      font-size: 26px;
      opacity: 1;
      color: #fff;
      line-height: 2rem;
      font-family: $primaryFont;
      display: block;
      margin-bottom: 0.8rem;
      margin-top: 2rem;
    }
  }
}

@media (min-width:576px){
.event-page ::v-deep .teaser {
    line-height: 1.5rem;

    &:before {
      font-size: 40px;
      line-height: 3rem;
      margin-bottom: 0.8rem;
      margin-top: 2rem;
    }
  }
}

</style>