<template>
    <article class="event">
        <router-link :to="detailLink" class="tile plain">
            <GrayCard>
                <div class="col1"> 

                    <div :class="['article--tag', this.cat.nid!=0 ? 'icon-left' : '', 'target'+this.cat.nid]">
                        <p class="label blue">{{ this.cat.name }}</p>
                    </div>
                    <h4 class="article--title gray">{{ articleData.attributes.title }}</h4>
                    <div class="article--meta date">
                        <p v-html="completeDate"></p>
                    </div>
                    <div class="article--meta contacts">
                        <p class="icon-left location">
                            {{ articleData.attributes.field_event_location_name + ((articleData.attributes.field_event_location_name && articleData.attributes.field_event_location_address) ? ', ' : '') }}{{articleData.attributes.field_event_location_address }}
                        </p>
                    </div>
                </div>
                <div v-if="articleData.field_image" class="col2">
                    <img class="event-image" :src="endpoint + articleData.field_image.attributes.uri.url" alt="">
                </div>
            </GrayCard>
        </router-link>
    </article>
</template>

<script>
import GrayCard from '../atomic-elements/GrayCard.vue'
export default {
    name: 'event-card',
    components: {
        GrayCard
    },
    data: () => {
        return { }
    },
    props: {
        articleData: Object,
        routesData: Array,
        cat: Object
    },
    computed: {
        startDate() {
            var year = new Date(this.articleData.attributes.field_start_date).toLocaleDateString("en", { year: 'numeric'}) 
            var month = new Date(this.articleData.attributes.field_start_date).toLocaleDateString("en", { month: 'short'}) 
            var day = new Date(this.articleData.attributes.field_start_date).toLocaleDateString("en", { day: 'numeric'}) 

            return '<span class="day-number">' + day + '</span> <span>' + month+ '</span> ' + year
        },
        endDate() {
            var year = new Date(this.articleData.attributes.field_end_date).toLocaleDateString("en", { year: 'numeric'}) 
            var month = new Date(this.articleData.attributes.field_end_date).toLocaleDateString("en", { month: 'short'}) 
            var day = new Date(this.articleData.attributes.field_end_date).toLocaleDateString("en", { day: 'numeric'}) 

            return '<span class="day-number">' + day + '</span> <span>' + month+ '</span> ' + year
        },
        completeDate() {
            return this.startDate + ' - ' + this.endDate
        },
        detailLink() {
            var path = ''
            var link = this.routesData.filter(r => r.nid == this.articleData.attributes.drupal_internal__nid)
            if(link.length>0)
                path = link[0].path
            else path = '/not-found'

            return path
        },
        endpoint(){
            return process.env.VUE_APP_ENDPOINT
        }
    },
    mounted() {
        //console.log(this.articleData, this.cat)
    }
}
</script>

<style lang="scss" scoped>

    .col2 .event-image {
        width: 100%;
    }

</style>